<template>
  <div>
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ $t("Gift") }} {{ $getByLang(name) }}</h1>
        </div>
      </div>
    </section>

    <!--SECTION START-->
    <section
      style="background-color: white; padding-top: 36px; padding-bottom: 150px"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <form method="post">
              <div class="mb-3">
                <label for="sendername" class="form-label">{{
                  $t("Sender's Name")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="sendername"
                  v-model="body.sendername"
                />
              </div>
              <div class="mb-3">
                <label for="personsNumber" class="form-label">
                  {{ $t("Service") }}
                </label>
                <b-form-select v-model="body.services_id" class="mb-3">
                  <b-form-select-option
                    v-for="item of servicesList"
                    :key="item.id"
                    :value="item.id"
                    >{{ $getByLang(item.name) }}</b-form-select-option
                  >
                </b-form-select>
              </div>
              <div class="mb-3" v-if="body.services_id">
                <label for="personsNumber" class="form-label">
                  {{ $t("Price Plan") }}
                    <span style="color:red">
                    {{ $t('per person') }}
                  </span>
                  
                   </label
                >
                <b-form-select v-model="body.prices_id" class="mb-3">
                  <b-form-select-option
                    v-for="item of pricesList"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.minutes }} {{ $t("minutes") }} =
                    {{ item.price }}€</b-form-select-option
                  >
                </b-form-select>
              </div>
              <div class="mb-3">
                <label for="personsNumber" class="form-label">
                  {{ $t("No. of Persons") }}</label
                >
                <input
                  id="sb-wrap"
                  wrap
                  min="1"
                  type="number"
                  v-model="body.numberPersons"
                  :max="userList.length"
                  placeholder="--"
                  class="form-control"
                />
              </div>

              <div class="mb-3">
                <label for="phone" class="form-label">{{
                  $t("Sender's Phone")
                }}</label>

                <vue-tel-input
                  v-model="body.phone"
                  v-bind="{
                    mode: 'international',
                  }"
                ></vue-tel-input>
              </div>
              <div class="mb-3">
                <label for="senderemail" class="form-label">{{
                  $t("Sender's Email address")
                }}</label>
                <input
                  type="email"
                  class="form-control"
                  id="senderemail"
                  v-model="body.senderemail"
                  placeholder="name@example.com"
                />
              </div>
              <!-- <div class=" mb-3">
                <label for="validationTooltip04">{{ $t('Pay Type') }}</label>
                <select
                  class="custom-select"
                  id="validationTooltip04"
                  v-model="body.baytype"
                >
                  <option :value="false">{{ $t('Cash') }}</option>
                  <option :value="true">{{ $t('Online') }}</option>
                </select>
              </div> -->

              <div class="mb-3">
                <label for="validationTooltip04">{{
                  $t("Recieve Type")
                }}</label>
                <select
                  class="custom-select"
                  id="validationTooltip04"
                  v-model="body.recieverType"
                >
                  <option value="email">{{ $t("email") }}</option>
                  <option value="himself">
                    {{ $t("Receive by himself") }}
                  </option>
                  <option value="post">{{ $t("post") }}</option>
                </select>
              </div>

              <div class="mb-3" v-if="body.recieverType == 'himself'">
                <label for="validationTooltip04">{{
                  $t("Recieve Date")
                }}</label>

                <b-form-datepicker
                  id="example-datepicker"
                  v-model="body.recieveDate"
                  class="mb-2"
                ></b-form-datepicker>
              </div>

              <div class="mb-3" v-if="body.recieverType == 'himself'">
                <label for="validationTooltip04">{{
                  $t("Recieve Time")
                }}</label>
                <b-form-timepicker
                  v-model="body.recieveTime"
                  locale="en"
                ></b-form-timepicker>
              </div>

              <div class="mb-3">
                <label for="RecieverName" class="form-label">{{
                  $t("Reciever's Name")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="RecieverName"
                  v-model="body.RecieverName"
                />
              </div>
              <div class="mb-3">
                <label for="note" class="form-label">{{ "note" }}</label>
                <textarea
                  class="form-control"
                  v-model="body.note"
                  id="note"
                  rows="3"
                ></textarea>
              </div>
              <p>
                {{ $t("Note:We can send") }}
              </p>
            </form>
          </div>
        </div>
        <b-button
          block
          variant="danger"
          :disabled="
            !this.body.sendername &&
            !this.body.RecieverName &&
            !this.body.phone &&
            !this.body.senderemail &&
            !this.body.numberPersons
          "
          @click="add"
          style="background-color: #f26838; color: white"
          >{{ $t("Confirm") }}</b-button
        >
      </div>
    </section>
    <div>
      <stripe-checkout
        ref="checkoutRef"
        mode="payment"
        :pk="publishableKey"
        :line-items="lineItems"
        :success-url="successURL"
        :cancel-url="cancelURL"
        @loading="(v) => (loading = v)"
      />
    </div>
  </div>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";
export default {
  components: {
    StripeCheckout,
  },
  data() {
    this.publishableKey =
      "pk_live_51HQAAeKJnzPR2F8FX59aaFrWGwpNLgd48uKrvRLB6F5FUYong46ShbrDaGbm2Qt1JrUazDb2XYEVLczJdiNOM0hF000flxqUoI";
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months prior
    const minDate = new Date(today);
    return {
      baseUploadURL2: this.$baseUploadURL2,
      minDate,
      name: null,
      id: null,
      value: null,

      dayWork: null,
      off: false,

      servicesList: [],
      pricesList: [],
      allPricesList: [],
      body: {
        //     'prices_id',
        //  'services_id',
        //  'massage_id',
        //     'sendername','senderemail','phone','RecieverName','numberPersons','note'
        sendername: null,
        RecieverName: null,
        phone: null,
        senderemail: null,
        note: null,
        numberPersons: 1,

        baytype: true,
        services_id: 1,
        prices_id: null,
        massage_id: null,
        recieverType: "email",
        recieveDate: new Date(),
        recieveTime: null,
      },
      userList: [],
      loading: false,
      lineItems: [
        {
          price: null, // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: null,
      cancelURL: null,
    };
  },

  created() {
    const slug = this.$route.params.slug;
    if (slug) {
      this.$http2.get(`massage/getById/${slug}`).then(
        (res) => {
          if (res.data) {
            this.name = res.data.name;
            this.id = res.data.id;
            this.body.massage_id = res.data.id;

            this.servicesList = res.data.services;

            this.allPricesList = res.data.prices;

            this.pricesList = [];
            for (const item of this.allPricesList) {
              if (item.services.find((el) => el.id == 1)) {
                this.pricesList.push(item);
              }
            }
            this.body.prices_id = this.pricesList[0].id;
          }
        },
        (err) => {
          console.log(err);
        }
      );
      // this.$http2.get(`daywork`).then(
      //   (res) => {
      //     if (res.data) {
      //       this.dayWork = res.data[0];
      //     }
      //   },
      //   (err) => {
      //     console.log(err);
      //   },
      // );
      this.$http2.get(`users`).then(
        (res) => {
          if (res.data) {
            this.userList = res.data;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  },
  methods: {
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },

    add() {
      if (
        this.body.sendername &&
        this.body.RecieverName &&
        this.body.phone &&
        this.body.senderemail &&
        this.body.numberPersons
      ) {
        this.lineItems[0].price = this.pricesList.find(
          (el) => el.id == this.body.prices_id
        ).api_id;
        this.lineItems[0].quantity = Number(this.body.numberPersons);
        this.$eventHub.$emit("showSpinner", true);
        this.$http2
          .post(`gifts/add`, {
            ...this.body,
          })
          .then(
            (res) => {
              if (this.body.recieverType == "post") {
                this.lineItems.push({
                  price: "price_1JPCVjKJnzPR2F8FyaqwsuKk",
                  quantity: 1,
                });
              }
              this.$eventHub.$emit("showSpinner", false);
              this.$swal.fire({
                icon: "success",
                title: "Success",
                text: !this.body.baytype
                  ? this.$t("Success Done")
                  : this.$t("toBay"),
                showConfirmButton: false,
                timer: 1500,
              });

              this.successURL = `https://egyptamassage.com${this.$getLink()}bayDone/gift/${res.data.id}/success`;
              this.cancelURL = `https://egyptamassage.com${this.$getLink()}bayDone/gift/${res.data.id}/error`;
              setTimeout(() => {
                this.submit();
              }, 1500);
            },
            (err) => {
              this.$eventHub.$emit("showSpinner", false);
              this.$swal.fire({
                icon: "error",
                title: "Error",
                text: this.$t("Something has gone wrong"),
                showConfirmButton: false,
                timer: 1500,
              });
            }
          );
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: this.$t("Fields must be filled out"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    getDayList(dateString) {
      if (dateString) {
        const days = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        const d = new Date(dateString);
        const dayName = days[d.getDay()];
        const option = { start: null, num: null, off: false };
        for (const key in this.dayWork) {
          if (key.startsWith(dayName) && key.endsWith("Start")) {
            option.start = this.dayWork[key];
          } else if (key.startsWith(dayName) && key.endsWith("Number")) {
            option.num = this.dayWork[key];
          } else if (key.startsWith(dayName) && key.endsWith("Hold")) {
            option.off = this.dayWork[key];
          }
        }
        const list = [Number(option.start)];
        const timeList = [];
        for (let i = 1; i < option.num; i++) {
          list.push(list[list.length - 1] + 2);
        }
        for (let i = 0; i < 24; i++) {
          timeList.push({
            key: i,
            time:
              String(i % 12 ? i % 12 : 12) + ":00 " + (i >= 12 ? "am" : "pm"),
          });
        }
        this.timeList = [];
        for (const item of list) {
          this.timeList.push({
            key: timeList.find((el) => el.key == item).key,
            time: timeList.find((el) => el.key == item).time,
          });
        }
      }
    },
  },
  watch: {
    value(dateString) {
      this.getDayList(dateString);
    },
    selected(newVal, old) {
      console.log(newVal);
      if (old.length != 0) {
        let reAdd = false;
        for (const item of newVal) {
          if (item.time != old[0].time) {
            reAdd = true;
          }
        }
        if (reAdd) {
          this.selected = [newVal[newVal.length - 1]];
        }
      }
    },
    "body.services_id"(val) {
      this.pricesList = [];
      for (const item of this.allPricesList) {
        if (item.services.find((el) => el.id == val)) {
          this.pricesList.push(item);
        }
      }

      this.body.prices_id = this.pricesList[0].id;
    },
  },
};
</script>

<style></style>
